/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import "~bootstrap-icons/font/bootstrap-icons.css";

/* Import vaiables */
@import "assets/sass/utilities/myVariables";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.snack-bar-panel {
  color: white !important;
  background-color: #eb4205 !important;
}

.snack-bar-panel-ok {
  color: white !important;
  background-color: #1f9415 !important;
}

.mat-simple-snackbar-action button {
  color: white;
}

.cdk-overlay-container {
  z-index: 1 !important;
}

// .mat-form-field.mat-focused .mat-form-field-label, .mat-form-field.mat-focused.mat-primary .mat-select-arrow, .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
//   color: #025159 !important;
// }

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #025159 !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #025159 !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #025159 !important;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #025159 !important;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: #5382C2;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(110, 112, 110, 0.6);
  outline: 0 none;
}

.col, .row, .col-12, .col-3, app-footer {
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

// Custom style input
.customInput {
  border: none;
  box-shadow: 0px 1px 1px #8e8e8e;
  border-radius: 0px;
  background-color: #ececec;
}

.customInput:hover {
  box-shadow: 0px 2px 1px #000000;
}

.inputSize::placeholder, textarea::placeholder  { 
  background-color: #ececec !important;/* Cambia el color del texto del placeholder */
}

//Custom style input dropdown
.mat-form-field-flex {  
  border-radius: 0% !important;
  background-color: #f6f6f6 !important;
  height: 51px;
  width: 100%;
  padding: 0.245em .75em 0 .75em;
}

.inputSize{
  margin-top: 0px;
  height: 50px;
  width: 88%;
  margin-bottom: 20px;
  background-color: #f6f6f6;
  padding: 10px;
}

.inputSize::placeholder{
  color:#575756;
  background-color: #f6f6f6 !important;
  font-size: 1em;
  font-weight: normal;
}

.customDropDown{
  width: 88%;
}

.lg-i3book-button{
  transition-duration: 0.4s;
  background-color:#5382C2;
  color: white;
  border-radius: 0% !important;
  font-size: 1em;
  height: 54px;
  width: 100px;
  padding: 8px !important;
  border: 1px solid#5382C2;
}